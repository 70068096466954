























import BaseButton from '@/components/base/BaseButton.vue';
import { useAuthStore } from '@/composables/useAuthStore';
import { User } from '@/models';
import { defineComponent } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router/useRouter';

type MenuItem = {
  text: string;
  to: string;
  auth: boolean;
  member: boolean;
  guest?: boolean;
};

export default defineComponent({
  components: { BaseButton },
  name: 'MemberMenu',
  setup() {
    const { isAuthed, isMember } = useAuthStore();
    const { router } = useRouter();
    const items = [
      { text: 'View Calendar', to: routeNames.home, guest: true },
      { text: 'Book a Space', to: routeNames.spaces.book, auth: false, member: true },
      { text: 'View Spaces', to: routeNames.spaces.all, auth: false, member: true },
      { text: 'My Bookings', to: routeNames.my.bookings, auth: true, member: true },
      { text: 'My Profile', to: routeNames.profile.details, auth: true, member: false },
      { text: 'FAQ & Help', to: routeNames.help, auth: false, member: true },
      { text: 'Contact', to: routeNames.contact, auth: false, member: false },
    ];

    const shouldShowItem = (item: MenuItem) => {
      if (item.guest && !isMember.value) {
        return true;
      }

      if (item.guest && isMember.value) {
        return false;
      }

      if (item.auth && !isAuthed.value) {
        return false;
      }

      if (item.member && !isMember.value) {
        return false;
      }

      return true;
    };

    const logout = async () => {
      await User.logout();
      router.push('/');
    };

    return {
      items,
      isAuthed,
      logout,
      shouldShowItem,
      routeNames,
      isMember,
    };
  },
});
