














































import BaseRouter from '@/components/BaseRouter.vue';
import ScheduleSelectInput from '@/components/inputs/ScheduleSelect/ScheduleSelectInput.vue';
import { defineComponent, ref } from '@/plugins/composition';
import AppBarIcon from './AppBarIcon.vue';
import MemberMenu from './MemberMenu.vue';
import { routeNames } from '@/router/routeNames';
import { useAuthStore } from '@/composables/useAuthStore';
import { useRouter } from '@/router/useRouter';
import BaseButton from '@/components/base/BaseButton.vue';

export default defineComponent({
  components: { AppBarIcon, ScheduleSelectInput, BaseRouter, MemberMenu, BaseButton },
  name: 'MemberLayout',
  setup() {
    const nav = ref(false);
    const toggleNav = () => {
      nav.value = !nav.value;
    };
    const { isAuthed, isMember } = useAuthStore();

    const { router } = useRouter();
    const goHome = () => {
      if (!isAuthed.value) {
        return router.push('/');
      }

      if (!isMember.value) {
        return router.push('/');
      }

      router.push({ name: routeNames.dashboard });
    };

    return {
      nav,
      toggleNav,
      routeNames,
      isAuthed,
      isMember,
      goHome,
    };
  },
});
